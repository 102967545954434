@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
font-display: swap;
// https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools
*/
@font-face {
  font-family: "Exo 2";
  src: url(./assets/font/exo-2/Exo2-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Exo 2";
  src: url(./assets/font/exo-2/Exo2-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}

* {
  font-family: "Exo 2", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

::-webkit-scrollbar {
  display: none;
  background-color: #fff;
  width: 12px;
}

@media screen and (min-width: 640px) {
  ::-webkit-scrollbar {
    display: block;
    display: none;
  }
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
  display: none;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 14px;
  border: 5px solid #fff;
  display: none;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
  display: none;
}

.text-word-spacing {
  word-spacing: 1.8px;
  letter-spacing: 0.6px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
